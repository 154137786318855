.home-service-section {
    // background-color: var(--color-jbs-primary);
    // background-image: linear-gradient(180deg, #2E54F6, #3365FA);

    .service {
        &:hover {
            // box-shadow: var(--shadow-light);
            // background-color: #5171f3;
            // background-color: #3960fd;
            box-shadow: 15px 15px 10px -15px rgba(1, 1, 1, 0.8); 
        }
    }

    

    .content {
        p {
            color: var(--color-light);
        }

        .title {
            color: var(--color-heading);
            a {
                &:hover {
                    color: blue !important;
                }
            }
        }
    }
    
}

// .home-mission-section {
//     background-color: var(--color-white);

//     h2 {
//         color: #181818;
//     }

//     p {
//         color: #65676b;
//     }

//     .rn-accordion-style .card {
//         background: #f5f8fa;

//         .card-header button {
//             color: #181818;
//         }

//         .card-body {
//             color: #65676b;
//             border-color: #d8d8d8;
//         }
//     }
// }